import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import Types from "./types";

const INITIAL_STATE = {
  list: [],
  subcategories: [],
  categoryDetail: {},
  subcategoryDetail: {},
  fetching: { state: false },
  config: {},
  error: { ...DEFAULT_NO_ERROR },
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_START:
      return { ...state, fetching: { state: true } };

    case Types.FETCH_SUCCESS: {
      let categoriesFlat = [];
      for (const cat of action.payload) {
        categoriesFlat = [...categoriesFlat, ...(cat.subcategories ?? [])];
      }
      return { ...state, list: action.payload, subcategories: categoriesFlat, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    }

    case Types.FETCH_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.UPDATE_START:
      return { ...state, fetching: { state: true }, config: { id: action.payload.id } };

    case Types.UPDATE_SUCCESS: {
      const list = [...state[action.payload.listKey]];
      const item = list.find(({ id }) => id === action.payload.item.id);
      const index = list.indexOf(item);
      if (index > -1) {
        Object.keys(action.payload.item).forEach((key) => {
          item[key] = action.payload.item[key];
        });
        list[index] = item;
      }
      return { ...state, [action.payload.listKey]: list, fetching: { ...DEFAULT_FETCHING_STATE }, config: {}, error: { ...DEFAULT_NO_ERROR } };
    }

    case Types.UPDATE_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, config: {}, error: { timestamp: Date.now(), message: action.payload } };

    case Types.CREATE_START:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };

    case Types.CREATE_SUCCESS:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, config: {}, error: { ...DEFAULT_NO_ERROR } };

    case Types.CREATE_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, config: {}, error: { timestamp: Date.now(), message: action.payload } };

    case Types.DELETE_START:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };

    case Types.DELETE_SUCCESS: {
      const list = [...state[action.payload.listKey]];
      const item = list.find((it) => it.id == action.payload.item.id);
      const index = list.indexOf(item);
      if (index > -1) {
        list.splice(index, 1);
      }
      return { ...state, [action.payload.listKey]: list, fetching: { ...DEFAULT_FETCHING_STATE }, config: {}, error: { ...DEFAULT_NO_ERROR } };
    }

    case Types.DELETE_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.POSITION_START:
      return { ...state, fetching: { state: true }, config: { id: action.payload.id } };

    case Types.POSITION_SUCCESS:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

    case Types.POSITION_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.RESET_ERROR:
      return { ...state, error: { ...DEFAULT_NO_ERROR } };

    default:
      return state;
  }
};

export default reducer;
