import styled from "styled-components";
import { PRIMARY, WRONG_DARK } from '@etiquette-ui/colors';

const PriceCell = styled.input.attrs({
  type: 'number',
})`
  background: inherit;
  border: 0px;
  outline: 0px;
  text-align: right;
  width: 65px;

  font-size: 14px;
  padding: 3px 3px;
  padding-left: 13px;
  border-radius: 8px;

  position: relative;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    outline: 2px solid ${PRIMARY};
    cursor: pointer;
  }  

  @media (min-width: 769px) {
    font-size: 15px;
    width: 75px;
  }

`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  span.price-tag,
  span.money-symbol {
    font-size: 13px;
  }

  span.price-tag {
    margin: auto;    
    font-weight: 600;
    color: ${PRIMARY};
    text-align: left;
  }

  span.money-symbol {
    font-weight: 400;
    color: inherit;
    pointer-events: none;
  }

  @media (min-width: 769px) {
    span.price-tag,
    span.money-symbol {
      font-size: 15px;
    }
  }
`;

const Input = styled.div`
  width: 100%;    
  max-width: 110px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Cancel = styled.span`
  width: 15px;
  height: 15px;

  &::before {
    content: 'x';  
    left: 0px;
    top: -4px;
    color: ${WRONG_DARK}; 
    font-size: 18px; 
    font-weight: 600;
    position: relative;
  }

  @media (min-width: 769px) {
    top: 10px;
    right: -10px;
  }
`;

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: 100%;

  > *:not(:first-child) {
    margin-top: 10px; 
  }

  @media (min-width: 769px) {
    justify-content: center;
  }
`;


export {
  PriceCell,
  Container,
  Input,
  Cancel,
  PricesContainer
}