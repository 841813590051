import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import BasicStep from './BasicStep';
import FormCard from 'containers/Form/FormCard';
import Groups from './Groups';
import useStep from 'hooks/useStep';
import { NavItem } from '@etiquette-ui/selectors';
import { BG_DARK } from '@etiquette-ui/colors';
import Ingredients from './Ingredients';

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
`;

const Layout = styled.div`
  width: 100%;
  min-height: 100%;
  margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 12px 8px;

  @media( min-width: 1024px ){
    width: 50%;
  }

  @media( max-width: 768px ){
    background-color: ${BG_DARK};
    border-radius: 0 0 24px 24px;
    padding: 14px 8px 8px 8px;
  }

`;

const Nav = styled(NavItem)`
  cursor: ${({withHover})=> withHover ? 'pointer' : 'default' };
  min-width: auto!important;
  padding: 5px 10px;
  margin: 0px;

  &, * {
    font-weight: ${({ selectable })=> selectable ? '500' : '300!important' };
  }

  @media( min-width: 1024px ){
    text-align: left;
  }
  
  @media( max-width: 768px ){
    font-size: 0.875rem;
    text-align: center;

    padding: 0px;

    * {
      font-size: inherit;
    }

    & div div div {
      margin-top: 2px;
      height: 2px;
      width: 30px;
    }
  }
`;

const ItemForm = ({ formData, dispatch, submit, title, button, isEdit=false, previousData }) => {
  const { translate } = useI18n();
  const [step,, next, changeStep] = useStep();

  return (
    <Container>
      <Layout>
        <IconLink to={'/menu/items'}>{translate('Back to Menu Items')}</IconLink>
        <TabsContainer>
          <Nav isActive={step === 0} onClick={ step > 0 ? () => changeStep(0) : null } withHover={step > 0} selectable={step >= 0}>
            <div>{translate('Basic Data')}</div>
          </Nav>
          <Nav isActive={step === 1} onClick={ step > 1 ? () => changeStep(1) : null } withHover={step > 1} selectable={step >= 1}>
            <div>{translate('Group')} </div>
          </Nav>
          <Nav isActive={step === 2} selectable={step === 2}>
            <div>{translate('Ingredients')} </div>
          </Nav>
        </TabsContainer>
        <FormCard className={ step === 0 ? 'isBasicDataStep' : ''} >
          {step === 0 && <BasicStep formData={formData} dispatch={dispatch} next={next} title={title} isEdit={isEdit} previousData={previousData} />}
          {step === 1 && <Groups formData={formData} dispatch={dispatch} next={next} title={title} />}
          {step === 2 && <Ingredients formData={formData} dispatch={dispatch} submit={submit} title={title} button={button} />}
        </FormCard>
      </Layout>
    </Container>
  );
};

export default ItemForm;
