import React from 'react';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import FormSection from 'containers/Form/FormSection';
import GridContainer from 'containers/Form/GridContainer';
import useI18n from 'i18n/useI18n';
import { ACTIONS } from '../../Reducer';
import WYSIWYG from '../../../../../../../../components/WYSIWYG';
import CATEGORY_KEYS, { CATEGORY_CONFIG_KEYS } from '../../keys';

const Details = ({ formData, dispatch }) => {
    const { translate } = useI18n();

    return (
        <FormSection>
            <Subtitle>{translate('Details')}</Subtitle>
            <FormSpacer />
            <GridContainer>
                <Input
                    labelProps={{ label: translate('Category Name (English)') }}
                    iconProps={{ icon: 'title' }}
                    inputProps={{
                        ...{
                            value: formData[CATEGORY_KEYS.TRANSLATIONS].en.title,
                            onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'en', text: 'title', value: e.target.value }),
                            required: true,
                        },
                    }}
                />
                <Input
                    labelProps={{ label: translate('Category Name (Spanish)') }}
                    iconProps={{ icon: 'title' }}
                    inputProps={{
                        value: formData[CATEGORY_KEYS.TRANSLATIONS].es.title,
                        onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'es', text: 'title', value: e.target.value }),
                        required: true,
                    }}
                />
            </GridContainer>
            <FormSpacer />
            <GridContainer>
                <WYSIWYG
                    onChange={(value) => dispatch(ACTIONS.CONFIG_MESSAGE, { key: 'translations.en.title', value })}
                    value={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.MESSAGE][CATEGORY_KEYS.TRANSLATIONS].en.title}
                    backgroundColor={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.MESSAGE].color}
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{ label: translate('Message (English)') }}
                />
                <WYSIWYG
                    onChange={(value) => dispatch(ACTIONS.CONFIG_MESSAGE, { key: 'translations.es.title', value })}
                    value={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.MESSAGE][CATEGORY_KEYS.TRANSLATIONS].es.title}
                    backgroundColor={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.MESSAGE].color}
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{ label: translate('Message (Spanish)') }}
                />
                <Input
                    labelProps={{ label: translate('Background Color') }}
                    iconProps={{ icon: 'number' }}
                    inputProps={{
                        ...{
                            value: formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.MESSAGE].color,
                            onChange: (e) => dispatch(ACTIONS.CONFIG_MESSAGE, { key: 'color', value: e.target.value }),
                            required: true,
                        },
                    }}
                />
            </GridContainer>
            <FormSpacer />
        </FormSection>
    );
};

export default Details;
