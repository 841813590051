import React from 'react';
import RowContainer from 'components/Table/RowContainer';
import CustomImage from 'components/Table/cells/CustomImage';
import { IMAGES_URL } from 'resources/constants/urls';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import Position from './cells/Position';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text';
import { formatMoney } from 'utils/text';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Order')} />,
            body: Position,
        },
        {
            flex: 2,
            header: ({ globalState }) => (
                <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Image')} />
            ),
            body: ({ item }) => (
                <CustomImage image={item.img ? `${IMAGES_URL}${item.img}` : null} shape={IMAGE_SHAPES.RECTANGLE} />
            ),
        },
        {
            flex: 6,
            header: ({ globalState, ...rest }) => (
                <ResponsiveHeaderText
                    property={'translations.en.title'}
                    text={globalState.config.translate('Item Name (English)')}
                    {...rest}
                />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations.en.title} />,
        },
        {
            flex: 6,
            header: ({ globalState, ...rest }) => (
                <ResponsiveHeaderText
                    property={'translations.es.title'}
                    text={globalState.config.translate('Item Name (Spanish)')}
                    {...rest}
                />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations.es.title} />,
        },
        {
            flex: 2,
            header: ({ globalState, ...rest }) => <ResponsiveHeaderText text={globalState.config.translate('Price')} {...rest} />,
            body: ({ item }) => <ResponsiveText text={formatMoney(item.price)} />,
        },
    ],
};

export default structure;
