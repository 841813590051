const ES = {
    "Search": "Buscar",
    "Log Out": "Cerrar Sesión",
    "Cancel": "Cancelar",
    "images_disclaimer": "La imagen debe estar en formato PNG, JPEG o JPG y de menos de 200 Kb.",
    "Maximum file size is:": "El tamaño máximo de archivo es: %size%",
    "Internal Server Error": "Error Interno del Servidor",
    "Please report this to the dev team.": "Favor de reportar esto al equipo de desarrollo.",
    "OK": "Ok",
    "Go Back": "Regresar",
    "Delete": "Eliminar",
    "Images from": "Images de",
    "Drop file here": "Soltar archivo aquí",
    "Drop files to upload or": "Soltar archivo aquí para cargar o",
    "Select File": "Seleccionar Archivo",
    "Save": "Guardar",
    "Open Menu Website": "Ir al Menú web",
    "Download": "Descargar",

    "Unauthorized": "No autorizado",
    "You don't have access to this app": "No tienes acceso a esta aplicación",

    "Menu Items": "Items del Menú",
    "Items": "Items",
    "Image": "Imagen",
    "Item Name": "Nombre del item",
    "Category": "Categoría",
    "Subcategory": "Subcategoría",
    "Price": "Precio",
    "Status": "Estado",
    "Multiple Categories": "Categorías Múltiples",
    "Multiple Subcategories": "Subcategorías Múltiples",
    "Pick one...": "Elige una...",
    "All Categories": "Todas las Categorías",
    "All Subcategories": "Todas las Subcategorías",
    "Without Subcategory": "Sin Subcategoría",
    "Inactive": "Inactivo",
    "Archived": "Archivado",
    "Active": "Activo",
    "86's no stock": "No stock 86",
    "Change status": "Cambiar estado",
    "Change Status": "Cambiar Estado",
    "On Menu": "En el Menú",
    "Family": "Familia",
    "Drinks": "Bebidas",
    "Food": "Comida",
    "Tags": "Tags",
    "Ingrediente (EN)": "Ingrediente (EN)",
    "Ingrediente (ES)": "Ingrediente (ES)",
    "Add Ingredient": "Añadir Ingrediente",
    "Copy Item Data": "Copiar Datos del Item",
    "Copy Data": "Copiar Datos",
    "Select where you want to arrange items": "Selecciona donde quieres ordenar los elementos",
    "Search Ingredient": "Buscar Ingrediente",
    "86 Management": "Administración de 86",
    "Archived Management": "Administración Pasada",
    "All Status": "Todos los Estátus",

    "Change to 86's no stock": "Cambiar a 86 sin stock",
    'Why is in "86\'s No Stock"?': '¿Por qué está en "86 Sin Stock"?',
    "Reason": "Razón",
    "When do you think this item will be in stock again?": "¿Cuando crees que el producto estará en stock de nuevo?",

    "Back to Menu Items": "Regresar a Items del Menú",
    "Back to Menu Subcategories": "Regresar a Subcategorías",
    "Back to Menu Categories": "Regresar a Categorías",
    "Basic Data": "Datos Básicos",
    "Group": "Grupo",
    "Ingredients": "Ingredientes",
    "New Item": "Nuevo Item",
    "Edit Item": "Editar Item",
    "Create Item": "Crear Item",
    "Update Item": "Actualizar Item",
    "Item Details": "Detalles",
    "Add Photo": "Añadir Foto",
    "Edit Photo": "Editar Foto",
    "Item Name (English)": "Nombre (Inglés)",
    "Item Name (Spanish)": "Nombre (Español)",
    "Description (English)": "Descripción (Inglés)",
    "Description (Spanish)": "Descripción (Español)",
    "POS Code": "Código POS",
    "Item visible and active": "Item visible y activo",
    "Item not visible and inactive": "Item oculto e inactivo",
    "Total Stock": "Stock Total",
    "Par Stock": "Stock Par",
    "Alert Stock": "Stock de Alerta",
    "Description required": "Descripción obligatoria",
    "Description optional": "Descripción opcional",
    "Click here to edit": "Clic aquí para editar",
    "Click here to lock": "Clic aquí para fijar",
    "Stock": "Stock",
    "Sponsor": "Patrocinador",
    "Continue": "Continuar",
    "Add Logo": "Añadir Logo",
    "Edit Logo": "Editar Logo",
    "New Tag": "Nueva Tag",
    "Update the code in ID Checkup": "Actualiza el código en Validación ID",

    "Categories": "Categorías",
    "Arrange": "Ordenar",
    "Icon": "Icon",
    "Category (EN)": "Categoría (EN)",
    "Category (ES)": "Categoría (ES)",
    "Message": "Mensaje",
    "Order": "Orden",
    "Icons not assigned": "Iconos no asignados",
    "Icon Set Selected": "Set de Íconos Seleccionado",
    "Apply Icon Set": "Aplicar Set de Íconos",
    "Add New": "Añadir Nueva",

    "New Category": "Nueva Categoría",
    "Edit Category": "Editar Categoría",
    "Create Category": "Crear Categoría",
    "Update Category": "Actualizar Categoría",
    "Back to Categories": "Regresar a Categorías",
    "Representative image": "Imagen representativa",
    "Details": "Detalles",
    "Category Name (English)": "Nombre de Categoría (Inglés)",
    "Category Name (Spanish)": "Nombre de Categoría (Español)",
    "Message (English)": "Mensaje (Inglés)",
    "Message (Spanish)": "Mensaje (Español)",
    "Settings": "Configuración",
    "Background Color": "Color de fondo",
    "Default view": "Vista por defecto",
    "Tags Filter Type": "Tipo de filtro de los tags",
    "Gallery View": "Vista Galería",
    "List View": "Vista Lista",
    "Accumulative": "Acumulativo",
    "Unique": "Único",
    "Expanded List": "Lista Expandida",
    "Minimized List": "Lista Minimizada",
    'With "Full Menu"': 'Con "Menú Completo"',
    'Without "Full Menu"': 'Sin "Menú Completo"',
    "Representative icon": "Ícono representativo",
    "Delete Category": "Eliminar Categoría",
    "Are you sure you want to delete this category?": "¿Estás seguro de querer eliminar esta categoría?",

    "Subcategories": "Subcategorías",
    "Delete Subcategory": "Eliminar Subcategoría",
    "Are you sure you want to delete this subcategory?": "¿Estás seguro de querer eliminar esta subcategoría?",
    "New Subcategory": "Nueva Subcategoría",
    "Edit Subcategory": "Editar Subcategoría",
    "Create Subcategory": "Crear Subcategoría",
    "Update Subcategory": "Actualizar Subcategoría",
    "Subcategory Name (English)": "Nombre de Subcategoría (Inglés)",
    "Subcategory Name (Spanish)": "Nombre de Subcategoría (Español)",
    "Back to Subcategories": "Regresar a Subcategorías",

    "86's Verification Page": "Página de Verificación de 86s",
    "Items History": "Historial de Productos",
    "Menu Item": "Item del Menú",
    "Who & When 86": "Quién y Cuándo 86",
    "Why 86 & Expected restock": "Por qué 86 & Restock esperado",
    "Today": "Hoy",
    "Overdue days": "Retrasado %days% días",
    "How long 86": "Cuánto tiempo 86",
    "days": "días",
    "History 86's Items": "Historial de Productos 86",
    "Removed from": "Removido de",
    "Pending...": "Pendiente...",
    "Unknown User": "Usuario Desconocido",
    "Comments": "Comentarios",
    "Unable to add comment": "No se puede añadir un comentario",
    "Why 86's (Not on menu)": "¿Por qué 86? (No en el menú)",
    "Add Comment": "Añadir Comentario",
    "History": "Historial",
    "Who 86": "Quién 86",
    "When 86": "Cuando 86",
    "Why 86": "Por qué 86",
    "LAST UPDATED": "ÚLTIMA ACTUALIZACIÓN",
    "PREVIOUS COMMENT": "COMENTARIO ANTERIOR",

    "86's Items History": "Productos 86 Histórico",
    "Back to 86's": "Regresar a 86",
    "86's frequency": "Frecuencia 86",
    "Last 30 days": "Últimos 30 días",
    "Last 90 days": "Últimos 90 días",
    "Last 365 days": "Últimos 365 días",

    "Power BI": "Power BI",
    "Select Report": "Seleccionar Reporte",
    "Please Select a Report": "Por favor Selecciona un Reporte",

    "Statistics": "Estadísticas",
    "Print": "Imprimir",
    "Start Date": "Fecha Inicio",
    "End Date": "Fecha Fin",
    "Select statistic": "Selecciona estadística",
    "Stars:": "Estrellas: %stars%",
    "Comments:": "Comentarios: %comments%",
    "Employees": "Empleados",

    "Actual Archived Items": "Items Archivados Actualmente",
    "Changed to archived": "Cambió a archivado",
    "Date of change": "Día del cambio",
    "Days archived": "Dias archivado",

    "ID Checkup": "Validación ID",
    "MENU - Item Name": "MENU - Nombre (EN)",
    "POS - Item Name": "POS - Nombre",
    "MENU - Price": "MENU - Precio",
    "POS - Price": "POS - Precio",
    "POS ID": "POS ID",
    "Filter": "Filtrado",
    "All": "Todos",
    "Only Errors": "Solo Errores",
    "Different Price": "Precio Distinto",
    "Not Assigned": "No Asignados",
    "POS Product Not Found": "Producto POS no Encontrado",
    "MENU - Presentation": "MENU - Presentación",
    "Item Status": "Status Item",
    "Presentation Status": "Status Presentación",

    "Choose an option": "Elige una opción",
    "Select Category": "Elige una Categoría",
    "Years": "Años",
    "Months": "Meses",
    "All options": "Todas",
    "stars": "Básico",
    "Percentages": "Porcentajes",
    "Totals": "Totales",
    "rated_total": "Total Evaluados",
    "Rating": "Promedio",
    "Report": "Reporte",
    "Options": "Opciones",
    "No data for selected period": "No hay datos para el periodo seleccionado",

    "food": "Alimentos",
    "breakfast": "Desayunos",
    "salads": "Ensaladas",
    "starters": "Entradas",
    "entrees": "Entradas",
    "desserts": "Postres",
    "sandwiches": "Sandwiches",
    "soups": "Sopas",
    "sushi": "Sushi",

    "mixology": "Mixología",

    "server": "Meseros",
    "speed": "Velocidad",
    "server_speed_fast": "Rápido",
    "server_speed_ok": "Buena Velocidad",
    "server_speed_slow": "Lento",
    "delivery": "Entrega platillos",
    "server_delivery_perfectly": "Perfecta",
    "server_delivery_adequate": "Adecuada",
    "server_delivery_slow": "Lenta",
    "server_delivery_noOpinion": "Sin opinión",
    "questions": "Conoce el menú",
    "server_questions_perfectly": "Perfecto",
    "server_questions_ok": "Ok",
    "server_questions_notGood": "No es bueno",
    "interruptions": "Interacciones",
    "server_interruptions_adequate": "Adecuadas",
    "server_interruptions_tooMany": "Demasiadas Interrupciones",
    "friendly": "Comportamiento",
    "server_friendly_superFriendly": "Super Amable",
    "server_friendly_friendly": "Amable",
    "server_friendly_unfriendly": "Hostil",
    "attitude": "Actitud",
    "server_attitude_smiling": "Siempre sonriente",
    "server_attitude_notSmiling": "Sonrie Suficiente",
    "server_attitude_frowning": "Disgustado",
    "temperature": "Temp. comida",
    "server_temperature_perfectly": "Perfecta",
    "server_temperature_adequate": "Adecuada",
    "server_temperature_incorrect": "Incorrecta",
    "server_temperature_noOpinion": "Sin opinión",

    "hostess": "Hostess",
    "communication": "Comunicación",
    "hostess_communication_great": "Genial",
    "hostess_communication_ok": "Ok",
    "hostess_communication_poor": "Pobre",
    "hostess_friendly_superFriendly": "Super Amigable",
    "hostess_friendly_friendly": "Ok",
    "hostess_friendly_unfriendly": "Hostil",
    "reservations": "Reservación de mesa",
    "hostess_reservations_helpful": "Muy Profes.",
    "hostess_reservations_noProblem": "Sin problema",
    "hostess_reservations_notHelpful": "No Profes.",
    "hostess_reservations_noOpinion": "En línea",
    "smiling": "Actitud",
    "hostess_smiling_smiling": "Sonriente",
    "hostess_smiling_notSmiling": "Ok",
    "hostess_smiling_frowning": "Disgustado",
    "courtesy": "Cortesía",
    "hostess_courtesy_offered": "Sí",
    "hostess_courtesy_notOffered": "No",

    "manager": "Managers",
    "manager_smiling_smiling": "Sonriente",
    "manager_smiling_notSmiling": "Ok",
    "manager_smiling_frowning": "Disgustado",
    "manager_friendly_superFriendly": "Super Amigable",
    "manager_friendly_friendly": "Ok",
    "manager_friendly_unfriendly": "Hostil",
    "waiting": "Tiempo de espera",
    "helpful": "Seguimiento",
    "manager_helpful_helpful": "Muy servicial",
    "manager_helpful_noHelpNeeded": "Ok",
    "manager_helpful_notHelpful": "No Servicial al Necesitarle",
    "perfection": "Perfección",
    "manager_perfection_dontRemember": "No recuerdo",
    "manager_perfection_serverInquired": "Si, mesero",
    "manager_perfection_someoneInquired": "Otro",
    "manager_perfection_nobodyInquired": "Nadie",
    "manager_perfection_managerCaptainInquired": "Si, Gerente",
    "checked": "Checked",
    "Percentages": "Porcentajes",
    "Totals": "Totales",
    "rated_total": "Total Evaluados",
    "Rating": "Promedio",
    "Report": "Reporte",
    "Options": "Opciones",
    "No data for selected period": "No hay datos para el periodo seleccionado",
    "Dates": "Fechas",

    'presentations': 'Presentaciones',
    'presentations_clarification_text': 'El nombre de la presentación no es requerido si solo una presentación esta disponible.',
    'add_presentation': 'Añadir Presentación',
    'presentation_name_en': 'Nombre de la Presentación (En)',
    'presentation_name_es': 'Nombre de la Presentación (Es)',
    'remove': 'Eliminar',
    'market_price_title': 'Precio Mercado',
    'market_price_description': 'Si se activa los precios se ignoran y "MKT" se mostrará en su lugar',

    
    "Preview": "Ver",
    "Main Page Card": "Tarjeta Destacada del Menú",
    "Main Page Card Settings": "Configuración de la Tarjeta Destacada del Menú",
    "Link Destination": "Link destino",
    "Section on Menu":"Sección en el Menú",
    "URL": "URL",
    "Out Of Stock Message": "Mensaje de Productos no Disponibles",
    "Out Of Stock Message Settings": "Configuración del Mensaje de Productos no Disponibles",
    "Promotion": "Promoción",
    "Promotion Image Settings": "Configuración de la imagen de promoción",
    "Promotion is disabled": "La imagen promocional no está activa",
    "Restaurant Logo": "Logo del restaurante",
    "Restaurant Logo On Menu": "Logo del restaurante en el Menú",
    "Message (Espanish)": "Mensaje (Español)",
    "Save And Apply": "Guardar",
    "Saving": "Guardando",
    "Image must be PNG, JPEG or JPG. We recommend portrait orientation.": "El formato de la imagen debe ser PNG, JPEG o JPG. Se recomienda una imagen vertical.",
    "Background color": "Color de Fondo",
    "Show on Menu": "Habilitar en el Menú",
}
export default ES;