import { HeaderSort } from '@etiquette-ui/dynamic-table-components';
import styled from 'styled-components';

const ResponsiveHeaderSort = styled(HeaderSort)`
    span {
        @media (max-width: 768px) {
            font-size: 14px !important;
        }
    }
`;

export default ResponsiveHeaderSort;
