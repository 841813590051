import React from 'react';
import Actions from 'components/Table/cells/Actions';
import { INPUTS } from '@etiquette-ui/colors';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { useHistory } from 'react-router';
import useCategories from 'state/categories';

const RowActions = ({ item, globalState, count }) => {
  const history = useHistory();
  const alert = useAlert()
  const [, categoriesDispatcher] = useCategories();
  
  const actions = [
    {
      event: () => {
        alert({
            type: ALERT_TYPES.CONFIRM,
            config: {
              title: globalState.config.translate('Delete Subcategory'),
              body: globalState.config.translate('Are you sure you want to delete this subcategory?'),
              closeText: globalState.config.translate('Go Back'),
              submitText: globalState.config.translate('Delete'),
            },
          })
          .then(() => {
            categoriesDispatcher.deleteStart('subcategories', { id: +item.id });
          })
          .catch(() => {});
      },
      icon: 'delete',
    },
    {
      event: () => history.push(`/menu/subcategories/edit/${item.id}`),
      icon: 'edit',
    },
  ];

  return (
    <Actions
      actions={actions}
      style={{ alignItems: 'start', marginTop: '10px' }}
      spinnerBackground={count % 2 === 0 ? INPUTS : 'white'}
    />
  );
};

export default RowActions;
