import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import FormCard from 'containers/Form/FormCard';
import FloatingButton from '../FloatingButton';
import FormSpacer from 'components/Form/FormSpacer';
import { ACTIONS } from './Reducer';
import Image from './Sections/Image';
import Details from './Sections/Details';
import Settings from './Sections/Settings';
import Icon from './Sections/Icon';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: scroll;
`;

const Layout = styled.div`
    width: 100%;
    min-height: 100%;
    margin-bottom: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
`;

const CategoryForm = ({ formData, dispatch, submit, title, button }) => {
    const { translate } = useI18n();

    const handleItemInfo = (field, value) => {
        const newField = { [field]: value };
        dispatch(ACTIONS.ITEM_INFO, newField);
    };
    
    return (
        <Container>
            <Layout>
                <IconLink to={'/menu/categories'}>{translate('Back to Categories')}</IconLink>
                <div style={{ height: '18px' }} />
                <FormCard>
                    <form style={{ width: '100%' }} onSubmit={submit}>
                        <FormTitle style={{ fontSize: '1.5rem', lineHeight: 'normal', textAlign: 'center' }}>{title}</FormTitle>
                        <FormSpacer />
                        <Image formData={formData} handleItemInfo={handleItemInfo} />
                        <FormSpacer />
                        <Icon formData={formData} dispatch={dispatch} />
                        <FormSpacer />
                        <Details formData={formData} dispatch={dispatch} />
                        <FormSpacer />
                        <Settings formData={formData} dispatch={dispatch} />
                        <FormSpacer />
                        <FormSpacer />
                        <FloatingButton type={'submit'}>{button}</FloatingButton>
                    </form>
                </FormCard>
            </Layout>
        </Container>
    );
};

export default CategoryForm;
