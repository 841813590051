import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import {
    getProcessedCategories,
    getTranslatedCategories,
    getProcessedSubcategories,
    getTranslatedCategory,
} from 'pages/MenuItems/utils/transformers';
import useCategories from 'state/categories';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';

const Filter = styled(Dropdown)`
    flex: 1;
    margin-right: 20px;
    > div {
        width: unset;
        @media (max-width: 768px) {
            min-width: 190px;
        }
    }

    @media (max-width: 768px) {
        margin-right: 5px;
        > div {
            min-width: 210px;
        }
    }
`;

const Filters = ({ data, setList, selectedCategory, setSelectedCategory, selectedSubcategory, setSelectedSubcategory}) => {
    const { translate, selectedLang } = useI18n();
    const [categories] = useCategories();
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [subcategoriesOptions, setSubcategoriesOptions] = useState([]);

    useEffect(() => {
        if (categories.list.length === 0 || categories.subcategories.length === 0) return;
        const processed = getProcessedCategories(categories);
        setCategoriesOptions(processed);
    }, [categories.list, categories.subcategories]);

    useEffect(() => {
        if (categoriesOptions.length === 0) return;
        setSelectedCategory(getTranslatedCategory(categoriesOptions[0], translate, selectedLang));
    }, [categoriesOptions, selectedLang]);

    useEffect(() => {
        if (!selectedCategory) return;
        setSubcategoriesOptions(getProcessedSubcategories(selectedCategory.subcategories));
    }, [selectedCategory]);

    useEffect(() => {
        if (subcategoriesOptions.length === 0) return;
        setSelectedSubcategory(getTranslatedCategory(subcategoriesOptions[0], translate, selectedLang));
    }, [subcategoriesOptions, selectedLang]);

    useEffect(() => {
        if (!selectedSubcategory) return;
        const newList = data.list.filter((p) =>
            p.categories.some(({ category }) => category === selectedSubcategory.value)
        ).sort((a, b)=> {
            const catA = a.categories.find(({ category }) => category === selectedSubcategory.value)
            const catB = b.categories.find(({ category }) => category === selectedSubcategory.value)

            if(!catA.position && !!catB.position) return 1;
            if(!!catA.position && !catB.position) return -1;
            return catA.position - catB.position;
        });

        setList(newList);
    }, [data.list, selectedSubcategory]);

    const onSelectCategory = (selected) => {
        setSelectedCategory(selected);
    };

    const onSelectSubcategory = (selected) => {
        setSelectedSubcategory(selected);
    };

    return (
        <>
            <Filter
                labelProps={{ label: translate('Category') }}
                selectProps={{
                    onChange: onSelectCategory,
                    options: getTranslatedCategories(categoriesOptions, translate, selectedLang),
                    placeholder: '',
                    value: selectedCategory,
                }}
            />
            <Filter
                labelProps={{ label: translate('Subcategory') }}
                selectProps={{
                    onChange: onSelectSubcategory,
                    options: getTranslatedCategories(subcategoriesOptions, translate, selectedLang),
                    placeholder: '',
                    value: selectedSubcategory,
                }}
            />
        </>
    );
};

export default Filters;
