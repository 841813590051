import React, { useEffect, useState } from 'react';
import { HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';
import { getPathValue } from 'utils/objectUtils';
import ResponsiveHeaderSort from '../responsive/HeaderSort';

const SortNumber = ({ applyFilter, resetFilters, filters, property, text }) => {
  const [type, setType] = useState(HEADER_SORT_TYPES.ASC);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (Array.from(filters.keys()).length === 0 && change) {
      if (type === HEADER_SORT_TYPES.ASC) {
        setType(HEADER_SORT_TYPES.DESC);
      } else {
        setType(HEADER_SORT_TYPES.ASC);
      }
      applyFilter(property, (items) => {
        return items.sort((a, b) => {
          const A = getPathValue(a, property) ?? 0;
          const B = getPathValue(b, property) ?? 0;
          if (A < B) {
            return type === HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
          if (A > B) {
            return type === HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          return 0;
        });
      });
      setChange(false);
    }
  }, [filters, change]);

  const onClick = () => {
    resetFilters();
    setChange(true);
  };

  return (
    <ResponsiveHeaderSort
      text={text}
      style={{ marginLeft: '16px' }}
      sortType={type}
      enabled={Array.from(filters.keys()).includes(property)}
      onClick={onClick}
    />
  );
};

export default SortNumber;
