import React from 'react';
import RowContainer from 'components/Table/RowContainer';
import CustomImage from 'components/Table/cells/CustomImage';
import { IMAGES_URL } from 'resources/constants/urls';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveHeaderSort from 'components/Table/responsive/HeaderSort';
import ResponsiveText from 'components/Table/responsive/Text';
import RowActions from './cells/Actions';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 1,
            header: ({ globalState }) => <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Image')} />,
            body: ({ item }) => <CustomImage image={item.img ? `${IMAGES_URL}${item.img}` : null} shape={IMAGE_SHAPES.RECTANGLE} />
        },
        {
            flex: 3,
            header: ({ globalState, ...rest }) => (
                <ResponsiveHeaderSort property={'translations.en.title'} text={globalState.config.translate('Category (EN)')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations.en.title} style={{ marginLeft: '16px' }}/>
        },
        {
            flex: 3,
            header: ({ globalState, ...rest }) => (
                <ResponsiveHeaderSort property={'translations.es.title'} text={globalState.config.translate('Category (ES)')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations.es.title} />
        },
        {
            flex: 2,
            header: () => <ResponsiveHeaderText text={''} />,
            body: RowActions,
        },
    ]
}


export default structure;