import React, { useEffect, useState } from 'react';
import { HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';
import { getCategoriesFromItem, getCategoryName } from 'pages/MenuItems/utils/transformers';
import ResponsiveHeaderSort from 'components/Table/responsive/HeaderSort';

const SortCategory = ({ applyFilter, resetFilters, filters, globalState, text }) => {
  const translate = globalState.config.translate;
  const selectedLang = globalState.config.selectedLang;
  const categories = globalState.config.categories;

  const [type, setType] = useState(HEADER_SORT_TYPES.ASC);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (Array.from(filters.keys()).length === 0 && change) {
      if (type === HEADER_SORT_TYPES.ASC) {
        setType(HEADER_SORT_TYPES.DESC);
      } else {
        setType(HEADER_SORT_TYPES.ASC);
      }
      applyFilter('category', (items) => {
        return items.sort((a, b) => {
          const catsA = getCategoriesFromItem(categories, a);
          const A = getCategoryName(catsA, translate, selectedLang).toUpperCase();

          const catsB = getCategoriesFromItem(categories, b);
          const B = getCategoryName(catsB, translate, selectedLang).toUpperCase();

          if (A < B) {
            return type === HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
          if (A > B) {
            return type === HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          return 0;
        });
      });
      setChange(false);
    }
  }, [filters, change]);

  const onClick = () => {
    resetFilters();
    setChange(true);
  };

  return (
    <ResponsiveHeaderSort
      text={text}
      sortType={type}
      enabled={Array.from(filters.keys()).includes('category')}
      onClick={onClick}
    />
  );
};

export default SortCategory;
